import React, {Component} from "react";
import {Query} from "react-apollo";
import gql from "graphql-tag";
import SpinnerPage from '../../ui/spinner';
import moment from 'moment';
import {Route, RouteComponentProps, Switch} from "react-router";
import Immutable from 'immutable';
import {createStore, ActionCreator, Reducer, Action} from 'redux'
import {Link} from "react-router-dom";

interface IFile {
    name: string,
    path: string,
    url: string,
    size: number,
}

const query = gql`
      query($date: String!) {
          images(date: $date, limit: 1000) {
            name
            url
          }
      }
    `;

interface IStoreState {
    images: Immutable.List<IFile>
}

export const SET_IMAGES = 'SET_IMAGES';

interface SetImagesAction extends Action {
    type: typeof SET_IMAGES,
    payload: Immutable.List<IFile>
}

const setImageAction: ActionCreator<SetImagesAction> = (images: Array<IFile>) => ({
    type: SET_IMAGES,
    payload: Immutable.List(images)
});

const initialState = {images: Immutable.List()};

const reducer: Reducer<IStoreState> = (state: IStoreState = initialState, action): IStoreState => {
    if (action.type === SET_IMAGES) {
        return {...state, images: (action as SetImagesAction).payload};
    }
    return state;
};

const store = createStore(reducer);

class Image extends Component<RouteComponentProps, {}> {

    render() {
        return (
            <Switch>
                <Route path={`${this.props.match.path}/:name`} component={ImageViewer}/>
                <Route component={Images}/>
            </Switch>
        );
    }
}

class Images extends Component<RouteComponentProps, {}> {

    render() {
        const date = moment()
            .add(-7, 'hours')
            .format('YYYYMMDD');
        return (
            <div style={{padding: 4}}>
                <Query query={query} variables={{date}}>
                    {({loading, error, data}) => {
                        if (loading) {
                            return <SpinnerPage/>;
                        } else if (error) {
                            return <span>{JSON.stringify(error)}</span>;
                        } else {
                            store.dispatch(setImageAction(data.images));
                            return <Imgs images={data.images} routePath={this.props.match.path}/>;
                        }
                    }}
                </Query>
            </div>
        );
    }
}

function Imgs({images, routePath}: { images: Array<IFile>, routePath: string }) {
    const displayImages = images.filter(((value, index) => !(index % 15)));

    return (
        <div style={{textAlign: "center"}}>
            {displayImages.reverse().map((i: IFile) => <Img key={i.name} image={i} routePath={routePath}/>)}
        </div>
    );
}

function Img({image, routePath}: { image: IFile, routePath: string }) {
    return (
        <div key={image.name} className="border border-light" style={{padding: 4, display: "inline-block"}}>
            <Link to={`${routePath}/${image.name}`}>
                <img src={image.url} alt={image.name} width={400} height={225}/>
            </Link>
        </div>
    );
}

interface IName {
    name: string
}

class ImageViewer extends Component<RouteComponentProps<IName>, {}> {

    render() {
        const state = store.getState();
        const name = this.props.match.params.name;
        const image = state.images.find(v => v.name === name);

        if (image == null) {
            return <p>getStore().image is null</p>;
        }

        return (
            <div style={{width: '100%', textAlign: 'center'}}>
                <img src={image.url} alt={image.name}/>
            </div>
        );
    }
}

export default Image;