import React, {Component} from "react";
import {Route, Switch, Link, RouteComponentProps} from "react-router-dom";
import Image from './image';
import Video from './video';

const {MDBBtn} = require("mdbreact");

class EGTB extends Component<RouteComponentProps, {}> {
    render() {
        return (
            <Switch>
                <Route path={`${this.props.match.path}/image`} component={Image}/>
                <Route path={`${this.props.match.path}/video`} component={Video}/>
                <Route component={Main}/>
            </Switch>
        );
    }
}

function Main({match}: RouteComponentProps) {
    return (
        <div>
            <Link to={`${match.path}/image`}>
                <MDBBtn color="primary">Image</MDBBtn>
            </Link>
            <Link to={`${match.path}/video`}>
                <MDBBtn color="primary">Video</MDBBtn>
            </Link>
        </div>
    );
}


export default EGTB;