import React, {Component} from "react";
import {Query} from "react-apollo";
import SpinnerPage from "../../ui/spinner";
import gql from "graphql-tag";
import {RouteComponentProps} from "react-router";

const query = gql`
      {
          videos(limit: 14) {
            name
            url
          }
      }
    `;

class Video extends Component<RouteComponentProps, {}> {
    render() {
        return (
            <div style={{padding: 4}}>
                <Query query={query}>
                    {({loading, error, data}) => {
                        if (loading) {
                            return <SpinnerPage/>;
                        } else if (error) {
                            return <span>{JSON.stringify(error)}</span>;
                        } else {
                            return <Videos videos={data.videos}/>;
                        }
                    }}
                </Query>
            </div>
        );
    }
}

interface IFile {
    name: string,
    path: string,
    url: string,
    size: number,
}

function Videos({videos}: { videos: Array<IFile> }) {

    return (
        <div style={{textAlign: "center"}}>
            {videos.reverse().map((v: IFile, index) => <VideoBlock key={v.name} video={v} index={index}/>)}
        </div>
    );
}

function VideoBlock({video, index}: { video: IFile, index: number }) {
    return (
        <div key={video.name} className="border border-light" style={{padding: 4, display: "inline-block"}}>
            <video width="400" height="225" preload={index === 0 ? 'auto' : 'none'} controls>
                <source src={video.url} type="video/mp4"/>
            </video>
        </div>
    );
}

export default Video;