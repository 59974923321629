import React, {Component} from "react";
import {RouteComponentProps} from "react-router";
// @ts-ignore
import WordCloud from 'react-d3-cloud';
import {Query} from "react-apollo";
import SpinnerPage from "../ui/spinner";
import {gql} from "apollo-boost";

const query = gql`
      query {
          brexit_word {
            text
            value: size
          }
      }
    `;

function rotate({text, value}: { text: string, value: number }) {
    const step = (value % 5) - 2;
    return step * 30;
}

function fontSizeMapper(size_min: number, size_max: number) {
    const m = (100 - 30) / (size_max - size_min);
    const c = 100 - (m * size_max);
    return ({value}: { value: number }) => {
        return (m * value) + c;
    }
}


class Brexit extends Component<RouteComponentProps, {}> {

    render() {
        return (
            <div style={{padding: 4}}>
                <Query query={query}>
                    {({loading, error, data}) => {
                        if (loading) {
                            return <SpinnerPage/>;
                        } else if (error) {
                            return <span>{JSON.stringify(error)}</span>;
                        } else {
                            const words = data.brexit_word
                                .sort((a: any, b: any) => b.value - a.value)
                                .slice(0, 500);
                            let size_min: number = Infinity, size_max: number = -Infinity;
                            words.forEach((w: any) => {
                                size_min = Math.min(size_min, w.value);
                                size_max = Math.max(size_max, w.value);
                            });
                            return <div>
                                <code>SELECT * FROM `status` WHERE `text` LIKE '%brexit%'</code>
                                <div/>
                                <code>13,094 rows</code>
                                <div style={{textAlign: 'center'}}>
                                    <WordCloud
                                        width={1400}
                                        height={800}
                                        data={words}
                                        fontSizeMapper={fontSizeMapper(size_min, size_max)}
                                        rotate={rotate}
                                    />
                                </div>
                            </div>
                        }
                    }}
                </Query>
            </div>
        );
    }
}

export default Brexit;