import React, {Component} from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {ApolloProvider} from "react-apollo";
import ApolloClient from "apollo-boost";

import EGTB from './egtb';
import Brexit from "./brexit";

let uri = process.env.REACT_APP_GRAPHQL_SERVER_URI;
if (uri == null && process.env.NODE_ENV === "development") {
    uri = "http://localhost:4000/graphql";
}
if (uri == null) {
    throw new Error("Missing REACT_APP_GRAPHQL_SERVER_URI!");
}

const client = new ApolloClient({uri});

class App extends Component {
    render() {
        return (
            <ApolloProvider client={client}>
                <Router>
                    <Switch>
                        <Route path="/egtb" component={EGTB}/>
                        <Route path="/brexit" component={Brexit}/>
                        <Route render={() => {
                            window.location.href = 'https://medium.com/@zinuzoid';
                            return null;
                        }
                        }/>
                    </Switch>
                </Router>
            </ApolloProvider>
        );
    }
}

export default App;
